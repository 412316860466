
<template>
  <ValidationObserver
    ref="userForm"
    v-slot="{ handleSubmit, failed }"
  >
    <b-toast
      :visible="failed"
      title="Warning"
      variant="warning"
    >
      {{ $t('messages.invalid_form') }}
    </b-toast>
    <form
      autocomplete="off"
      @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.firstname')"
        label-for="firstname"
      >
        <b-form-input
          id="firstname"
          v-model="userInfo.name"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('user.username')"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="userInfo.username"
          readonly
        ></b-form-input>
      </b-form-group>
      <!-- <div class="text-right">
        <b-button
          class="mr-2"
          to="/dashboard"
          variant="light"
        >
          {{ $t('buttons.back')}}
        </b-button>
        <b-overlay
          :show="user.isUpdatingProfile"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.save') }}
          </b-button>
        </b-overlay>
      </div> -->
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      userData: {
        email: '',
        phone_number: '',
        firstname: '',
        lastname: '',
        registration_url: '',
      },
      file: null,
      contactForm: {
        line_id: '',
        qr_code: '',
        message: '',
      },
      announcements: '',
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['adminRole', 'userInfo']),
    userId() {
      return this.userInfo.id
    },
  },
  watch: {
    userInfo(val) {
      if (val) {
        this.setData()
      }
    },
  },
  methods: {
    ...mapActions(['updateProfile']),
    setData() {
      if (this.userId && this.userInfo) {
        const data = JSON.parse(JSON.stringify(this.userInfo))
        this.userData = {...data}
      } else {
        this.userData = {
          email: '',
          phone_number: '',
          firstname: '',
          lastname: '',
          registration_url: '',
        }
      }
    },
    onSubmit() {
      this.updateProfile(this.userData)
    },
  },
}
</script>
